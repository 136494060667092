import React, {  useEffect, useState } from 'react';

import './connectAccount.scss'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function PhoneOtp() {
    const bvn = localStorage.getItem('bvn')
    const navigate = useNavigate();
    const [formData, setFormData] = useState({phone:''});
    const [isLoading, setIsLoading] = useState(false);
    const [isInputClicked, setIsInputClicked] = useState({
        phone: false
       
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleInputBlur = (inputName) => {
        setIsInputClicked((prevState) => ({
            ...prevState,
            [inputName]: false,
        }));
    };

    const handleClose = () => {
        // navigate('/pages/dashboard');
    };
    const handleInputContainerClick = (inputName) => {
        setIsInputClicked((prevState) => ({
            ...prevState,
            [inputName]: true,
        }));
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
    
        try {
            
            const result = {
                bvn:bvn,
                phoneNumber:formData.phone,
            }
            console.log("result data: ",result)
            const url = "https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect-altnum";

            const res = await axios.post(url, { ...result });
            console.log(res.data)
            setIsLoading(false);
            navigate('/pages/enter-otp')
        }catch(err){
            setIsLoading(false);
            console.log(err)
            toast.error("An error occured: Please try again")
        }
    }
  return (
    <div className="connect-account-overlay">
            <div className="connect-account-main">
                <div className="connect-account-box">
                    <div className='close-button'>
                        <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleClose}/>
                    </div>
                    <div className='flick-logo' style={{marginTop:"0px"}}>
                        <img src={process.env.PUBLIC_URL + "/images/round-flick-logo.svg"} alt="close"/>
                    </div>
                    <p className='super-wallet'>Enter Your Phone Number</p>
                    <div className='enter-credentials-box'>
                        <form className="flick-link-form" style={{ marginTop: '10px' }} onSubmit={handleSubmit}>
                      
                                <div className="input_container" onBlur={() => handleInputBlur('phone')}>
                                    <div className={`input-group ${isInputClicked.phone ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('phone')}>
                                        <input type="tel" name="phone" placeholder="Phone" className={`input-name`} onChange={handleInputChange} required />
                                    </div>
                                    <p className={`input-label ${isInputClicked.phone ? 'clicked' : ''}`}>Phone</p>
                                </div>
                            
                                <button className={`btn`} style={{ background: '#2EBDB6', marginTop: "10px" }} type="submit" disabled={isLoading}>
                                    {isLoading ? (
                                        <BeatLoader color="#FFF" cssOverride={{}} />
                                    ) : (
                                        <>Proceed</>
                                    )}
                                </button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer className="custom-toast-container" />
        </div>
  )
}

export default PhoneOtp