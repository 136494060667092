import axios from "axios";
// import PouchDB from 'pouchdb';
// const apikey;
// const db = new PouchDB('FlickPouchDb');

// export const getLivePublicKey = async () => {

//   try {
//     // const publicKeyDoc = await db.get('flickmydoc');
//     //console.log("flickmydoc axios ", publicKeyDoc.livePublicKey)
//     // apikey = publicKeyDoc.livePublicKey
//     const livePublicKey = localStorage.getItem("livePublicKey")

//     return livePublicKey; //publicKeyDoc.livePublicKey;
//   } catch (err) {
//     console.log(err);
//     return 'no-key'; 
//   }
// };




// const publicKeyDoc = await db.get('flickmydoc');
//console.log("flickmydoc axios ", publicKeyDoc.livePublicKey)
// apikey = publicKeyDoc.livePublicKey
// const apiKey =  publicKeyDoc.livePublicKey;



//console.log("the pulic key ",apiKey)

export const apiPost = (url, data, conf = {}, auth = true,customHeaders={}) => {
    
    // const getEncodedValue = (clearText) => {
    //     return btoa(encodeURI(clearText));
    //  }
    
      const getDecodedValue = (encodedValue) =>{
            return decodeURI(atob(encodedValue));
     }
        

     var livepubblicKey = localStorage.getItem("livePublicKey");
     if (livepubblicKey !== null){
         livepubblicKey = getDecodedValue(livepubblicKey);
     }

    //  come back to re use this later
    //  const apiKey = livepubblicKey;
     const apiKey = localStorage.getItem("livePublicKey");
          
  const defaultHeaders ={
    Authorization: `Bearer ${apiKey}`,
    'Content-Type': 'application/json'

  }
    const config = {
      ...conf,
      headers: {
        ...defaultHeaders,
        ...customHeaders,
      },
    };

    // console.log("url: ",url, " data: ", data)
    console.log("config: ", config)
    return axios.post(url, data, config);
  };

  export const apiGet = (url, conf = {}, auth = true,customHeaders={}) => {

    // const getEncodedValue = (clearText) => {
    //     return btoa(encodeURI(clearText));
    //  }
    
      const getDecodedValue = (encodedValue) =>{
            return decodeURI(atob(encodedValue));
     }

     var livepubblicKey = localStorage.getItem("livePublicKey");
     if (livepubblicKey !== null){
         livepubblicKey = getDecodedValue(livepubblicKey);
     }
     const apiKey = livepubblicKey;
    
    const defaultHeaders ={
      Authorization: `Bearer ${apiKey}`,
    }
    const config = {
      ...conf,
      headers: {
        ...defaultHeaders,
        ...customHeaders,
      },
    };
    return axios.get(url, config);
};