import React, {  useEffect, useState } from 'react';
import '../directdebitlookup/connectAccount.scss'
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'; 

function Dashboard() {
    const dashboard = JSON.parse(localStorage.getItem('dashboard'));
    const navigate = useNavigate();
    const [isVbnConnectLoading,setIsVbnConnectLoading] = useState(false)


    // useEffect(()=>{
    //     bvnConnect();
    // },[bvn])
    const [isLoading, setIsLoading] = useState(false);
    const initialState = {
        address: '',
        email: '',
        phone: '',
        bvn: ''
    };
    
    const [formData, setFormData] = useState(initialState);
    const [isInputClicked, setIsInputClicked] = useState({
        address: false,
        email: false,
        phone: false,
        bvn: false
    });
    
    const handleClose = () => {
        navigate('/direct-debit');
    };
    
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleInputBlur = (inputName) => {
        setIsInputClicked((prevState) => ({
            ...prevState,
            [inputName]: false,
        }));
    };



    // const bvnConnectApproval = async()=>{
    //     setIsVbnConnectLoading(true);
  
    //     try{
  
    //       if(source === "dashboard" ){
    //         setIsVbnConnectLoading(false);
    //       }
  
    //       if(source !=="dashboard" && !bvn ){
  
    //         return toast.error("No bvn provided")
           
    //       } else if(source !== "dashboard" && bvn){
    //         // console.log("working here")
    //         setIsVbnConnectLoading(false);
    //         const result = {
    //           bvn:bvn, 
    //         }
    //         const url ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect"
    //         await axios.post(url,{...result}) 
    //       }
           
    //     }catch(err){
    //       console.log(err);
    //       setIsVbnConnectLoading(false);
    //     }
  
    //     setTimeout(() => {
    //       setIsVbnConnectLoading(false);
    //     }, 50000); // 40 seconds in milliseconds
  
    //   }




    // const handleSubmit = async (e) => {
    //     setIsLoading(true)
    //     e.preventDefault();
        
    //     try{

    //         const headers= {
    //             Authorization: `Bearer ${localStorage.getItem("publicKey")}`,
    //             "Content-Type": "application/json",
    //         }
    //         if(formData.bvn){

    //             localStorage.setItem("bvn",formData.bvn);
    //         }

    //         const payload = {
    //             bvn: formData.bvn,
    //             address:formData.address,
    //             phone: formData.phone,
    //             email: formData.email,
    //             CustomerCode: dashboard?.CustomerCode,
    //             transactionId: dashboard?.transactionId
    //         }

    //         const result ={
    //             bvn:formData.bvn, 
    //         }

          
    //          const url2 ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/get-select-options"
    //          let res2 = await axios.post(url2,{...result},{headers:headers}) 
         
    //          const data = res2.data.data
    
    //         navigate('/pages/bvn-consent',{state:{data}});
    //         setIsLoading(false)

    //     }catch(err){
    //         setIsLoading(false)
    //         console.log(err)
    //         const errorMessage = err?.response?.data?.message || "An Error has occured"
    //         toast.error(errorMessage);
    //     }
     
       
    // };


    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
    
        try {
            const headers = {
                Authorization: `Bearer ${localStorage.getItem("publicKey")}`,
                "Content-Type": "application/json",
            };
    
            if (formData.bvn) {
                localStorage.setItem("bvn", formData.bvn);
            }
    
            const result = {
                bvn: formData.bvn,
            };

            
            // Run the first API request without waiting for the response, and handle any potential errors
            const url = "https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect";
            
            try {
                axios.post(url, { ...result }).catch((error) => {
                    console.log("First API call error (ignored):", error.message);
                });
            } catch (error) {
                console.log("First API call exception (ignored):", error.message);
                // Ignoring the error, you can also add custom handling if needed
            }
    
            // Wait for 50 seconds before running the second API request
            setTimeout(async () => {
                try {
                    const url2 = "https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect-approval"
                    const url3 = "https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/get-select-options";
                    const res2 = await axios.post(url2,{...result});

                    const {status} = res2.data
                

                    if(status==="00"){
                        setIsLoading(false);
                        navigate('/pages/direct-accounts')
                    }else if(status === "02"){
                        let res3 = await axios.post(url3, { ...result });
                        const data = res3.data.data;
                        navigate('/pages/bvn-consent', { state: { data } });
                    }else if(status === "05"){
                        navigate('/pages/verify-phone')
                    }
    
                   
                } catch (error) {
                    console.log("Second API call error:", error.message);
                    // Handle the error if the second API call fails
                    toast.error("Failed to fetch select options.");
                } finally {
                    setIsLoading(false);
                }
            }, 10000); // 10 seconds in milliseconds
    
        } catch (err) {
            setIsLoading(false);
            console.log(err);
            const errorMessage = err?.response?.data?.message || "An Error has occurred";
            toast.error(errorMessage);
        }
    };
    


    const handleInputContainerClick = (inputName) => {
        setIsInputClicked((prevState) => ({
            ...prevState,
            [inputName]: true,
        }));
    };
    
    return (
        <div className="connect-account-overlay">
            <div className="connect-account-main">
                <div className="connect-account-box">
                    <div className='close-button'>
                        <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleClose}/>
                    </div>
                    <div className='flick-logo' style={{marginTop:"0px"}}>
                        <img src={process.env.PUBLIC_URL + "/images/round-flick-logo.svg"} alt="close"/>
                    </div>
                    <p className='super-wallet'>Enter Your Details Below</p>
                    <div className='enter-credentials-box'>
                        <form className="flick-link-form" style={{ marginTop: '10px' }} onSubmit={handleSubmit}>
                        {/* dashboard.is_required_address || dashboard.is_required_bvn || dashboard.is_required_email || dashboard.is_required_phone */}
                            {
                                dashboard?.is_required_address &&
                                <div className="input_container" onBlur={() => handleInputBlur('address')}>
                                    <div className={`input-group ${isInputClicked.address ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('address')}>
                                        <input type="text" name="address" placeholder="Address" className={`input-name`} onChange={handleInputChange} required />
                                    </div>
                                    <p className={`input-label ${isInputClicked.address ? 'clicked' : ''}`}>Address</p>
                                </div>
                            }
                            {
                                dashboard?.is_required_email &&
                                <div className="input_container" onBlur={() => handleInputBlur('email')}>
                                    <div className={`input-group ${isInputClicked.email ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('email')}>
                                        <input type="email" name="email" placeholder="Email" className={`input-name`} onChange={handleInputChange} required />
                                    </div>
                                    <p className={`input-label ${isInputClicked.email ? 'clicked' : ''}`}>Email</p>
                                </div>
                            }
                            {
                                dashboard?.is_required_phone &&
                                <div className="input_container" onBlur={() => handleInputBlur('phone')}>
                                    <div className={`input-group ${isInputClicked.phone ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('phone')}>
                                        <input type="tel" name="phone" placeholder="Phone" className={`input-name`} onChange={handleInputChange} required />
                                    </div>
                                    <p className={`input-label ${isInputClicked.phone ? 'clicked' : ''}`}>Phone</p>
                                </div>
                            }
                            {
                                dashboard?.is_required_bvn &&
                                <div className="input_container" onBlur={() => handleInputBlur('bvn')}>
                                    <div className={`input-group ${isInputClicked.bvn ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('bvn')}>
                                        <input type="text" name="bvn" placeholder="BVN" className={`input-name`} onChange={handleInputChange} required />
                                    </div>
                                    <p className={`input-label ${isInputClicked.bvn ? 'clicked' : ''}`}>BVN</p>
                                </div>
                            }
                            <button className={`btn`} style={{ background: '#2EBDB6', marginTop: "10px" }} type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <BeatLoader color="#FFF" cssOverride={{}} />
                                ) : (
                                    <>Proceed</>
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer className="custom-toast-container" />
        </div>
    );
}

export default Dashboard