import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
// import PouchDB from 'pouchdb';

 function DirectAccountConnected() {

  const navigate = useNavigate();
  // eslint-disable-next-line 
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line 
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  // eslint-disable-next-line 
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
    


  const handleSaveLater = async () => {
    setIsLoadingLink(true);
    navigate("/pages/continue-later");
  }
  
  const handleContinue = () => {
    setIsLoading(true)
    // navigate("/pages/another-link");
    navigate("/pages/direct-accounts");
  }

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className='success-flick'>
            <img src={process.env.PUBLIC_URL + "/images/success-icon.svg"} alt="logo"/>
            <img src={process.env.PUBLIC_URL + "/images/flick-white-border.svg"} alt="logo" style={{position:"absolute", bottom:"0", right:"100px"}}/>
          </div>

          <div className='connected' style={{marginBottom:"0"}}>
            <p className='account-connected'>Account Connected!</p>
            <p className='your-account' style={{width:"100%"}}>Your 1st account has been successfully <br/> linked; RaveLoan requires you to link 1 more.</p>
          </div>
            <div className='connected-btn'> 
              <button className="btn" onClick={handleSaveLater} style={{ background: '#FFF', color:"#161925", border: "1px solid #EAECF0"}} disabled={isLoadingLink || isLoadingDashboard}>
                {isLoadingLink ? (
                  <BeatLoader
                    color="#FFF"  
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Save & Continue later
                  </>
                )}
              </button>

              <button className="btn" onClick={handleContinue} disabled={isLoadingLink || isLoadingDashboard}>
                {isLoadingDashboard ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Link Next
                  </>
                )}
              </button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default DirectAccountConnected;

