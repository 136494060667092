import React, { useState } from "react";
import "./connectAccount.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { apiPost } from '../utilities/api/axios';
import { ToastContainer, toast } from "react-toastify";
// import { useBackground } from './BackgroundProvider';
function InternalConnectAccounts() {

  // const { backgroundImage } = useBackground();

  // const pageStyle = {
  //   backgroundImage: `url(${process.env.PUBLIC_URL + '/images/' + backgroundImage})`,
  // };
  const navigate = useNavigate();
  const location = useLocation();
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');
  const accountData = location.state ? location.state.accountData : [];
//   const sessionIdParsed = location.state ? location.state.sessionIdPassed : [];
  const loginId = location.state ? location.state.loginId : [];
  const selectedBankCode = location.state ? location.state.selectedBankCode : [];

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const sessionId = localStorage.getItem('sessionId')


  const handleOptionChange = (account) => {
    setSelectedAccount(account);
  };

  const handleClick = async () => {
    try {
      setIsLoading(true);

      if (selectedAccount !== null) {

        let url = "https://linked-landing-e581a0da63a9.autoidleapp.com/select-multiple-accounts";
        const data = {
            loginId: loginId,
            accountNumber: selectedAccount.account,
            bank_code: selectedBankCode,
            openbankingId:sessionId
        };

        console.log("selectedAccountIndex payload ", url)
        console.log("index data",data)
        const response = await apiPost(url, data);
        
        console.log("selectedAccountIndex response")
        console.log(response)

        if (response.status === 200) {
          console.log("Data:", response.data);
          setIsLoading(false);
          navigate("/pages/account-connected");
            
        } else {
          setIsLoading(false);
          console.error("An error occurred while connecting the account.");
          toast.error("An error occurred. Please try again later.");
        }

      } else {
        setIsLoading(false);
        console.error("No account selected.");
        toast.error("Please select an account.");
      }

    } catch (error) {
      setIsLoading(false);
      console.error("Error connecting account:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleBack = () => {
    navigate("/pages/enter-credentials");
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + "/images/arrowBack.svg"}
              alt="back"
              style={{ width: "16px", height: "16px" }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Choose your bank</p>
            <img
              src={process.env.PUBLIC_URL + "/images/close.svg"}
              alt="close"
              style={{ marginBottom: "27px" }}
              onClick={handleBack}
            />
          </div>
          <div style={{ padding: "0px 21px 21px 21px" }}>
            <div className="connect-accounts-image">
              <div className="connect-accounts">
                <p className="connect-one">Connect one or more accounts</p>
                <p className="account-name">Account name: <span style={{ color: "#151F32" }}>{accountData && accountData.length > 0 ? accountData[0].cusname : ''}</span></p>
              </div>
              <img
                src={process.env.PUBLIC_URL + selectedBankIcon}
                alt="logo"
                style={{ width: "34px", height: "35.4px" }}
              />
            </div>
            <div className="account-type-container">
              {accountData && accountData.length > 0 && accountData.map((account, index) => (
                <div className="account-type" key={index} onClick={() => handleOptionChange(account)}>
                  <div className="account-type-text">
                    <p className="type-of-account">{account.accounttype}({account.currency})</p>
                    <p className="account-number">{account.account}</p>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value={account.accounttype}
                    checked={selectedAccount === account}
                    onChange={() => handleOptionChange(account)}
                  />
                </div>
              ))}
            </div>
            <button className="btn" style={{ background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
            {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
                </>
              )}
            </button>
            <div className="secured-flick">
              <img
                src={process.env.PUBLIC_URL + "/images/secured-by-flick.svg"}
                alt="flick"
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default InternalConnectAccounts;
