import React from 'react';
import { useNavigate } from 'react-router-dom';

 function InvalidAccount() {
  const navigate = useNavigate();


  const handleBack = () => {
    navigate('/pages/direct-enter-credentials');
  };

  const handleContinue = async () => {
    navigate("/pages/direct-accounts")
  }

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
        <div className="choose-bank" style={{justifyContent:"flex-end"}}>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              style={{ marginBottom: '27px' }}
              onClick={handleBack}
            />
          </div>
          <div className='success-flick' style={{marginTop:"37px"}}>
            <img src={process.env.PUBLIC_URL + "/images/invalid.svg"} alt="logo"/>
          </div>

          <div className='consent'>
            <p className='account-connected'>Account doesn’t meet requirement</p>
            <p className='your-account' style={{width:"80%"}}>The account you linked does not meet RaveLoans requirement for Direct Debit </p>
          </div>
          <div className='consent-btn'>
              <button className="btn" style={{background:" #EAF8F8", color:"#2EBDB6"}} onClick={handleContinue}>
              Link other account 
              </button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default InvalidAccount;

