import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css";
import NotFoundPage from './components/pages/NotFoundPage';
import InvalidPage from './components/pages/InvalidPage';
import Success from './components/pages/Success';
import BVNConsent from './components/directdebitlookup/BVNConsent';
import TransactionOtp from './components/directdebitlookup/TransactionOtp';
import ConsentReceived from './components/directdebitlookup/ConsentReceived';
import RaveLoan from './components/directdebitlookup/RaveLoan';
import DirectAssociatedAccounts from './components/directdebitlookup/DirectAssociatedAccounts';
import DirectAssociatedCredentials from './components/directdebitlookup/DirectAssociatedCredentials';
import InvalidAccount from './components/directdebitlookup/InvalidAccount';
import InputOtp from './components/directdebitlookup/InputOtp';
import DirectChooseBank from './components/directdebitlookup/DirectChooseBank';
import DirectAccountConnected from './components/directdebitlookup/DirectAccountConnected';
import DirectMandateConfirmation from './components/directdebitlookup/DirectMandateConfirmation';
import OpenDirectSelectBanks from './components/directdebitlookup/OpenDirectSelectBanks';
import DirectChooseYourAccount from './components/directdebitlookup/DirectChooseYourAccount';
import DirectLinkAccount from './components/directdebitlookup/DirectLinkAccount';
import AnotherLink from './components/directdebitlookup/AnotherLink';
import DirectAssociatedChooseYourBank from './components/directdebitlookup/DirectAssociatedChooseYourBank';
import AnotherChooseYourBank from './components/directdebitlookup/AnotherChooseYourBank';
import AnotherCredentials from './components/directdebitlookup/AnotherCredentials';
import AnotherOtp from './components/directdebitlookup/AnotherOtp';
import ConnectSuccessful from './components/directdebitlookup/ConnectSuccessful';
import ContinueLater from './components/directdebitlookup/ContinueLater';
import SetupComplete from './components/directdebitlookup/SetupComplete';
import VerifyPage from './components/pages/VerifyPage';
import Dashboard from './components/pages/Dashboard';
import InternalConnectAccounts from './components/directdebitlookup/InternalConnectAccounts';
import PhoneOtp from './components/directdebitlookup/PhoneOtp';
import Camera from './components/directdebitlookup/Camera';

function App() {
  return (
    <Router>
        <Routes>
          <Route>
            <Route path="/invalid" element={<InvalidPage/>} />
            <Route path="/:id" element={<VerifyPage />} />
            <Route path="/pages/confirm-mandate" element={<DirectMandateConfirmation />} />
            <Route path="/pages/bvn-consent" element={<BVNConsent />} />
            <Route path="/pages/enter-otp" element={<TransactionOtp />} />
            <Route path="/" element={<NotFoundPage />} />
            <Route path="/checkout-success" element={<Success />} />
            <Route path="/pages/direct-choose-account" element={<DirectChooseYourAccount />} />
            <Route path="/pages/direct-link-account" element={<DirectLinkAccount />} />
            <Route path="/pages/consent-received" element={<ConsentReceived />} />
            <Route path="/pages/rave-loan" element={<RaveLoan />} />
            <Route path="/pages/open-direct-select-bank" element={<OpenDirectSelectBanks/>} />
            <Route path="/pages/open-associated-choose-bank" element={<DirectAssociatedChooseYourBank/>} />
            <Route path="/pages/direct-accounts" element={<DirectAssociatedAccounts/>} />
            <Route path="/pages/direct-enter-credentials" element={<DirectAssociatedCredentials />} />
           
           
            <Route path="/pages/verify-phone" element={<PhoneOtp/>} />
            <Route path="/pages/direct-choose-bank" element={<DirectChooseBank/>} />
            <Route path="/pages/invalid" element={<InvalidAccount />} />
            <Route path="/pages/consent-fee" element={<InputOtp />} />
            <Route path="/pages/connected" element={<DirectAccountConnected/>} />
            <Route path="/pages/another-link" element={<AnotherLink />} />
            <Route path="/pages/another-choose-bank" element={<AnotherChooseYourBank />} />
            <Route path="/pages/another-credentials" element={<AnotherCredentials />} />
            <Route path="/pages/another-bank-otp" element={<AnotherOtp />} />
            <Route path="/pages/connect-successful" element={<ConnectSuccessful />} />
            <Route path="/pages/continue-later" element={<ContinueLater />} />
            <Route path="/pages/setup-complete" element={<SetupComplete />} />
            <Route path="/pages/dashboard" element={<Dashboard />} />


            <Route path="/pages/face" element={<Camera />} />
            <Route path="/pages/internal-connect-accounts" element={<InternalConnectAccounts />} />
          </Route>
        </Routes>
    </Router>
  );
}

export default App;