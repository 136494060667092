import React, { useEffect, useState } from "react";
import "./connectAccount.scss";
import { useNavigate} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast} from "react-toastify";
import axios from "axios";
import BankListIcon from '../directdebitlookup/bankListIcon.json'
import TableLoader from "../common/TableLoader";

function DirectAssociatedAccounts() {
  const personal = JSON.parse(localStorage.getItem('personal'));
  const business = JSON.parse(localStorage.getItem('business'));
  const bvn = localStorage.getItem('bvn')
  const livePublicKey = localStorage.getItem('publicKey')
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectBtn, setSelectBtn] = useState(null);
  const [isBankListLoading,setIsBankListLoading] = useState(false);
  const [bankList,setBankList] = useState([])
  let bankIcon = [...personal,...business];

  useEffect(()=>{
    getBankList();
    
  },[])

  

  // const getBankList = async()=>{
    
  //   setIsBankListLoading(true)
  //   try{
  //     // const result = {
  //     //   "bvn":"22346162884",
  //     //   // "bvn":"22257905523",
  //     // }
  //     const result = {
  //       "bvn":bvn,
  //     }
    
  //     console.log("bank List bvn: ",result)

  //     const url ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/account-list"

  //     let res = await axios.post(url,{...result}) 
  //     setIsBankListLoading(false)
  //     console.log("bankList:",res.data.data.data.data.data);

  //     const bankList = res.data.data.data.data.data;

  //     // Synchronously map over the bankList to update the AccountDesignationName
  //   const updatedBankList = bankList.map(bank => {
  //     if (bank.AccountDesignationName === "INDIVIDUAL") {
  //       return { ...bank, AccountDesignationName: "PERSONAL_BANKING" };
  //     } else if (bank.AccountDesignationName === "BUSINESS") {
  //       return { ...bank, AccountDesignationName: "BUSINESS_BANKING" };
  //     }
  //     return bank;
  //   });
  //     setBankList(updatedBankList);
  //   }catch(err){
  //     setIsBankListLoading(false)
  //     console.log(err)
  //   }
  // }


  
  const getBankList = async () => {
    setIsBankListLoading(true);
    const maxRetries = 3;
    let attempts = 0;
    let success = false;
  
    while (attempts < maxRetries && !success) {
      try {
        const result = {
          bvn: bvn,
        };
  
        console.log("Attempting to fetch bank list, attempt number:", attempts + 1);
  
        const url = "https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/account-list";
        const res = await axios.post(url, { ...result });
        // console.log("bankList:", res.data.data.data.data.data);
  
        const bankList = res.data.data.data.data.data;
  
        if (bankList && bankList.length > 0) {
          // Synchronously map over the bankList to update the AccountDesignationName
          const updatedBankList = bankList.map((bank) => {
            if (bank.AccountDesignationName === "INDIVIDUAL") {
              return { ...bank, AccountDesignationName: "PERSONAL_BANKING" };
            } else if (bank.AccountDesignationName === "BUSINESS") {
              return { ...bank, AccountDesignationName: "BUSINESS_BANKING" };
            }
            return bank;
          });
          setBankList(updatedBankList);
          success = true; // Mark the request as successful
        } else {
          throw new Error("Empty bank list returned");
        }
      } catch (err) {
        console.log(`Error on attempt ${attempts + 1}:`, err.message);
        attempts++;
  
        if (attempts < maxRetries) {
          console.log(`Retrying in 20 seconds...`);
          await new Promise((resolve) => setTimeout(resolve, 20000)); // Wait for 20 seconds before retrying
        } else {
          console.log("Max retries reached, giving up.");
          // Handle the failure case here if needed, e.g., show an error message to the user
        }
      } finally {
        if (attempts >= maxRetries || success) {
          setIsBankListLoading(false);
        }
      }
    }
  };


  const handleClick = async () => {
    if (!selectedBank) {
      toast.error("Please select a bank");
      return;
    }

    const selectedBankInfo = bankIcon.find(
      (bank) =>
        bank.bankCode === selectedBank.BankCode && bank.type === selectedBank.AccountDesignationName
    );

    if (selectedBankInfo) {
      localStorage.setItem('selectedBankIcon', selectedBankInfo.icon);
      localStorage.setItem('selectedBankCode', selectedBankInfo.bank_code);
      localStorage.setItem('bankingType', selectedBankInfo.type);
      localStorage.setItem('bankName', selectedBankInfo.name);
      localStorage.setItem('authMethod', JSON.stringify(selectedBankInfo.auth_methods));
    } else {
      return toast.error("No matching bank found. Please choose another bank from the list.");
    }

    navigate("/pages/direct-choose-bank")
  };


  const handleConnect = async () => {
    setIsLoading(true)
    navigate("/pages/open-direct-select-bank")
  };
  

  const handleBankChange = (event) => {
    setSelectBtn(event.target.value);
    
  };


  const handleBack = () => {
    navigate("/pages/rave-loan");
  };

  const getBankIcon = (bankCode) => {
    const bank = bankIcon.find(bank => bank.bankCode === bankCode);
    return bank ? bank.icon : '/images/bankIcon.svg';
  };

  const getBankDetails = (bankCode) => {
    const bank = bankIcon.find(bank => bank.bankCode === bankCode);
    return bank ? bank.name: '';
  };
  
  const getBankNameWithCode = (bankCode) => {
    const bank = bankIcon.find(bank => bank.bankCode === bankCode);
    return bank ? bank.icon : '';
  };

  return (
    <div className="connect-account-overlay">
     
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + "/images/arrowBack.svg"}
              alt="back"
              style={{ width: "16px", height: "16px" }}
              onClick={handleBack}
            />
            <img
              src={process.env.PUBLIC_URL + "/images/close.svg"}
              alt="close"
              onClick={handleBack}
            />
          </div>
          <div className="associated-account" style={{borderBottom:"1px solid rgba(231, 234, 238, 0.40)", paddingBottom:"10px"}}>
            <img src={process.env.PUBLIC_URL + "/images/ass-flick.svg"} alt="logo"/>
            <p className="link-associated">Link Account</p>
            <p className="rave-we-found">RaveLoan has requested you to link a min. of two (2) active accounts</p>
          </div>
          <div className="account_list_scroll">
            <div style={{ padding: "0px 21px 21px 21px" }}>
              

            

              <div className="account-type-container">
                {
                  bankList.map((bank,index)=>(

                  <div className="account-type" onClick={() => setSelectedBank(bank)}>
                    <div className="account-type-text" style={{display:"flex", flexDirection:"row"}}>
                    <div>
                      <img style={{width:"24px",height:"24px"}} src={getBankIcon(bank.BankCode)} alt="logo"/>
                    </div>
                    <div>
                      <p className="type-of-account">{bank.BankName}</p>
                      <p className="account-number">{bank.accountnumber.replace(/.(?=.{4})/g, '*')}</p>
                    </div>
                    </div>
                    <input
                      type="radio"
                      name="options"
                      value={bank.BankName}
                      checked={selectBtn === bank.BankName}
                      onChange={handleBankChange}
                      className="custom-radio"
                    />
                  </div>
                  ))
                }
              </div>

              
              
            </div>
          </div>
          <div style={{ padding: "0px 21px 21px 21px",marginTop:"29px" }}>
            <button className="btn" style={{ background: "#2EBDB6" }} onClick={handleClick} disabled={isLoading}>
                {isLoading ? (
                      <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                      />              ) : (
                    <>
                      Continue
                      <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px", width:"14px", height:"14px" }}/>
                    </>
                  )}
            </button>
            <button className="btn" style={{ background: "#FFF", color:"#161925", marginTop:"10px", border:"1px solid #EAECF0" }} onClick={handleConnect} disabled={isLoading}>
            {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              ) : (
                <>
                  Connect another account
                </>
              )}
            </button>

          </div>
        </div>
      </div>
      {
        isBankListLoading && <TableLoader />
      }
      <ToastContainer />
    </div>
  );
}

export default DirectAssociatedAccounts;
