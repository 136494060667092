import React, { useEffect, useState } from 'react';
import './connectAccount.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import TableLoader from '../common/TableLoader';

function BVNConsent() {
// eslint-disable-next-line
  const navigate = useNavigate();
  const [otpData, setOtpData] = useState([]);
  const location = useLocation()
  const [isLoading,setIsLoading] = useState(false)
  const [isPhoneLoading,setIsPhoneLoading] = useState(false)

  const [selectedCurrency, setSelectedCurrency] = useState("+234");

  const  {data} =  location?.state || {}
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const bvn = localStorage.getItem('bvn')

  const isNigMobileNumber = (num) => /^0\d{10}$/.test(num);

  



    useEffect(() => {
      if (data) {
        // Convert keys to an array
        const keysArray = Object.keys(data);
        setOtpData(keysArray);
      }
    }, [data]);

    const handleClick = async(value) => {
      setIsLoading(true)
      try{

        // const headers = {  
        //   Authorization:`Bearer ${auth?.livePublicKey}`
        // }
      
        const result = {
          "bvn":bvn,
          "selectedOption":value
        }
      
        console.log("result bvn: ",result)

        const url ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/select-option"

        let res = await axios.post(url,{...result}) 

        console.log("response:",res.data)
        // const data = res.data.data

        setIsLoading(false)
        navigate('/pages/enter-otp',{state:{value}});
      }catch(err){
        setIsLoading(false)
        console.log(err)
      }
    }

    const handleSelectCurrency = (e) => {
      setSelectedCurrency(e.target.value);
    };

  const flagImage = () => {
    switch (selectedCurrency) {
      case '+234':
        return <img src={`/images/flag.svg`} alt='icon' />;
      case '+256':
        return <img src={`/images/uganda.svg`} alt='icon' />;
      case '+254':
        return <img src={`/images/kenya.svg`} alt='icon' />;
      case '+233':
        return <img src={`/images/ghana.svg`} alt='icon' />;
      default:
        return null;
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;

    // Only allow numeric input
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);

      if (value.length === 0 || value[0] !== '0') {
        setIsValid(false);
        showError('Phone number must start with 0');
      } else {
        setIsValid(true);
        setErrorMessage('');
      }
    }
  };

  useEffect(() => {
    if (phoneNumber.length === 11) {
      if (isNigMobileNumber(phoneNumber)) {
        handleSubmit();
      } else {
        setIsValid(false);
        showError('Phone number must start with 0');
      }
    }
  }, [phoneNumber]);

  const handleSubmit = async() => {
    setIsPhoneLoading(true);
    let value = phoneNumber
    try{

      console.log('Phone number submitted:', phoneNumber);
      const result = {
        "bvn":bvn,
        "selectedOption":phoneNumber
      }
    
      console.log("result: ",result)

      const url ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/select-option"

      let res = await axios.post(url,{...result}) 

      console.log("response:",res.data)
      // const data = res.data.data

      setIsPhoneLoading(false)
      navigate('/pages/enter-otp',{state:{value}});
    }catch(err){
      setIsPhoneLoading(false);
      console.log(err)
    }

    // Your submit logic here
  };

  const showError = (message) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(''), 10000);
  };


  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
         <div className='verify-bvn'>

         <div className="identity-verify">
            <div>
                <img src={process.env.PUBLIC_URL + '/images/flickLogo.svg'} alt="logo"/>
            </div>
            <p className='will-send'>We will send an OTP to verify your identity</p>
          </div>

            <div className='otp-verify'>


            {otpData.map((key, index) => (
                <div key={index} className='phone-email'  onClick={()=>handleClick(key)}>
                  <div className='verify-otp'>
                    <div>
                      <img src={process.env.PUBLIC_URL + (data[key] === "sms0-d" ? '/images/phone-otp.svg' : '/images/email-otp.svg')} alt="logo" />
                    </div>
                    <div className='verify-mode'>{key}</div>
                  </div>
                  <img src={process.env.PUBLIC_URL + '/images/arrow-right.svg'} alt="logo" />
                </div>
              ))}

              
            </div>

            <div className='other'>
                <div className='or-line'></div>
                <p className='or'>Or</p>
                <div className='or-line'></div>
            </div>

            <div className='mobile-number'>
                <p className='your-current'>Enter your current banking mobile number below</p>

                <div>
                    <div className="select-code">
                    <div className='flag-code'>
                    {flagImage()}
                        <select value={selectedCurrency} onChange={handleSelectCurrency} className='input-select-code'>
                        <option value='+234'>+234</option>
                        <option value='+256'>+256</option>
                        <option value='+254'>+254</option>
                        <option value='+233'>+233</option>
                        </select>
                    </div>
                    <div>
                    <input
                      pattern="[0-9]*"
                      maxLength="11"
                      inputMode="numeric"
                      placeholder="Enter Phone Number"
                      className={`input-phone-number ${!isValid ? 'input-error' : ''}`}
                      value={phoneNumber}
                      onChange={handleChange}
                    />
                    
                  </div>
                 
                    
                    </div>
              </div>
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
         </div>
        </div>
      </div>
        {
          (isLoading || isPhoneLoading) &&
          <TableLoader />
        }
      <ToastContainer />

    </div>
  );
}

export default BVNConsent;
