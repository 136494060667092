import React, { useEffect } from 'react'
import './connectAccount.scss'
import { useNavigate } from "react-router-dom";
import axios from 'axios';

function RaveLoan() {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate("/pages/direct-accounts");
    };
    const handleClose = () => {
        navigate("/pages/consent-received")
    }
    // useEffect(()=>{
    //     fetchBank();
    // },[])
  

    // const fetchBank = async()=>{
    //     try{
    //         const url = "https://flick-app.com/merchant/get-connect-banks"
    //         const res =  await axios.get(url)
    //         console.log(res.data.data);
    //         const {business_banks,personal_banks} = res.data.data
    //         // console.log("business_banks",business_banks)
    //         // console.log("personal_banks",personal_banks)
    //         localStorage.setItem("personal",JSON.stringify(personal_banks));
    //         localStorage.setItem("business",JSON.stringify(business_banks));

    //     }catch(err){
    //         console.log(err)
    //     }
    // }


   

  return (
    <div className='connect-account-overlay'>
        <div className='connect-account-main'>
            <div className='connect-account-box'> 
                <div  className='close-button'>
                    <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleClose}/>
                </div>
                <div className='flick-logo'>
                    <img src={process.env.PUBLIC_URL + "/images/flick-black-logo.svg"} alt="close"/>
                </div>
                <p className='super-wallet'>RaveLoan uses <span style={{fontWeight:"500"}}>Flick</span> to connect your account</p>
                <div className='connect-effortless'>
                   <div className='border-line'>
                        <div className='connect-title'>
                            <img src={process.env.PUBLIC_URL + "/images/connect.svg"} alt="logo"/>
                            <div className='connect-link'>
                                <p className='connect-your-account'>Secure</p>
                                <p className='securely-connect' style={{marginBottom:"18px"}}>Your data is encrypted and your details will not be accessible to RaveLoan</p>
                            </div>
                        </div>
                    </div>
                    <div className='connect-title'>
                        <img src={process.env.PUBLIC_URL + "/images/your-data.svg"} alt="logo"/>
                        <div className='connect-link'>
                            <p className='connect-your-account'>You have control</p>
                            <p className='securely-connect'>Flick or RaveLoan cannot share your data access your funds without your permission</p>
                        </div>
                    </div>
                </div>
                <div className='flick-policy'>
                    <div className='agree-to'>
                        <p className='select-continue'>By selecting “Continue” you agree to the</p>
                        <a href="https://getflick.app/privacy-policy" className='flick-end-user'>Flick End User Privacy Policy</a>
                    </div>
                    <button className="btn" style={{background:"#2EBDB6"}} onClick={handleClick}>
                        Continue
                        <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
                    </button>
                </div>

            </div>
        </div>
    </div>
  )
}

export default RaveLoan