import React, { useState } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

function ContinueLater() {
// eslint-disable-next-line
  const navigate = useNavigate();
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  // eslint-disable-next-line 
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = () => {
    navigate('/pages/connected');
};

const handleClose = async () => {
    setIsLoadingLink(true);
    navigate('/pages/connected');
  }
  
  const handleContinue = () => {
    setIsLoading(true)
    navigate("/pages/another-link");
  }
  return (
    <div className="connect-account-overlay" >
      <div className="connect-account-main" style={{paddingTop:"80px"}}>
        <div className="connect-account-box">
         <div className='choose-bank'>
            <img src={process.env.PUBLIC_URL + "/images/arrowBack.svg"} alt="back" style={{ width: "16px", height: "16px" }} onClick={handleBack}/>
            <img src={process.env.PUBLIC_URL + '/images/flickLogo.svg'} alt="logo"/>
            <p> </p>
          </div>
          <div className="associated-account" style={{marginTop:"35px"}}>
            <p className="link-associated">Scan Code to continue Later</p>
            <p className="we-found" style={{fontSize:"14px", width:"100%"}}>The link has also been forwarded to your email <span style={{color:"#111928", borderBottom:"1px solid #111928"}}>ay*****de@gmail.com</span></p>
          </div>
         <div className='verify-bvn'>
            <div className='qr'>
                <img src={process.env.PUBLIC_URL + "/images/scan-qr-code.svg"} alt="qr"/>
            </div>

            <div className='other'>
                <div className='or-line'></div>
                <p className='or'>Or</p>
                <div className='or-line'></div>
            </div>

            <div className='continue-'>
                <img src={process.env.PUBLIC_URL + "/images/copy.svg"} alt="copy"/>
                <h4 className='save-link'>https:cncnf3ed4edvJS42EDN23dd3d34rf43df <br/>4f4f4f4f4321768k8865g4</h4>
            </div>
            <div className='connected-btn' style={{padding:"37px 0 0 0"}}>
            <button className="btn" onClick={handleClose} style={{ background: '#FFF', color:"#161925", border: "1px solid #EAECF0"}} disabled={isLoadingLink || isLoading}>
                {isLoadingLink ? (
                  <BeatLoader
                    color="#FFF"  
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Close 
                  </>
                )}
              </button>

              <button className="btn" onClick={handleContinue} disabled={isLoadingLink || isLoading}>
                {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Continue Now
                  </>
                )}
              </button>
            </div>
         </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ContinueLater;
