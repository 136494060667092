import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

 function SetupComplete() {
  const navigate = useNavigate();

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      window.location.replace('/');
    });

    return () => {
      window.removeEventListener('popstate', function(event) {
        window.location.replace('/');
      });
    };
  }, []);
  const handleClose = () => {
    navigate("/")
}

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
        <div  className='close-button'>
          <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleClose}/>
        </div>
          <div className='success-flick'>
            <img src={process.env.PUBLIC_URL + "/images/success-icon.svg"} alt="logo"/>
            <img src={process.env.PUBLIC_URL + "/images/flick-white-border.svg"} alt="logo" style={{position:"absolute", bottom:"0", right:"100px"}}/>
          </div>

          <div className='connected'>
            <p className='account-connected'>Your direct debit setup is complete!</p>
            <p className='your-account'>The first debit on your account will be made after an <span style={{color:"#151F32"}}>24-hour</span> waiting period has elapsed.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupComplete;

