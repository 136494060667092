import React, { useState, useEffect } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import PersonalBankingData from "../directdebitlookup/PersonalBanking.json";
import BusinessBankingData from "../directdebitlookup/BusinessBanking.json";
import { apiPost } from '../utilities/api/axios';
import OtpModal from './otp/OtpModal';
import SecretModal from './otp/SecretModal';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


function timeout(number) {
  return new Promise((res) => setTimeout(res, number));
}
function DirectAssociatedCredentials() {
  const newId = uuidv4();
  const navigate = useNavigate();
  const personal = JSON.parse(localStorage.getItem('personal'));
  const business = JSON.parse(localStorage.getItem('business'));
  const selectedBankCode = localStorage.getItem('selectedBankCode');
  const selectedBankIcon = localStorage.getItem("selectedBankIcon");
  const selectedBankName = localStorage.getItem("bankName");
  const livePublicKey = localStorage.getItem('bvn')
  const sessionId = localStorage.getItem('sessionId')

  // eslint-disable-next-line
  const bankingType = localStorage.getItem('bankingType') || 'PERSONAL_BANKING';
  // eslint-disable-next-line
  const selectedOption = localStorage.getItem('selectedOption') || 'internet_banking';
   // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [id, setId] = useState('');
  // eslint-disable-next-line
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  // eslint-disable-next-line
  const [isSecretModalOpen, setIsSecretModalOpen] = useState(false);
  const selectedBank = localStorage.getItem('selectedBank');
  // ============ 
  // const responseBanks = JSON.parse(localStorage.getItem("internalBanks"));
  const bankDataArray = [
    ...personal,
    ...business,
  ];


  useEffect(() => {
    // console.log('Selected Bank Code:', selectedBankCode);
    const savedId = localStorage.getItem('sessionId');
    if (savedId) {
      setId(savedId);
    }
  }, [selectedBankCode]);

  const handleBack = () => {
    navigate('/pages/direct-choose-bank');
  };

  // const initialState = {
  //   bank_code: selectedBankCode,
  //   code_password: '',
  //   id:'',
  //   username: '',
  //   password: ''
  // };

  const initialState = {
    bank_code: selectedBankCode,
    code_password: "",
    id: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [isInputClicked, setIsInputClicked] = useState({
    userId: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };

 


  const formDataFields = (() => {
    const bankData = bankDataArray.find(
      (bank) => bank.bank_code === selectedBankCode && bank.type === bankingType
    );

    const authMethod = bankData.auth_methods.find(
      (method) => method.type === selectedOption
    );


    if (authMethod && authMethod.ui && authMethod.ui.form) {
      return authMethod.ui.form.map((field, index) => (
        <div
          className="flick-link-inner"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginBottom: "23.5px",
          }}
          key={index}
        >
          <div
            className="input_container"
            onBlur={() => handleInputBlur(field.name)}
          >
            {console.log("field name: ",field.name)}
            <div className={`input-group ${isInputClicked[field.name] ? "clicked" : ""}`} onClick={() => handleInputContainerClick(field.name)}>
              <input
                type={field.type}
                name={field.name}
                placeholder={field.hint}
                className="input-name"
                onChange={handleInputChange}
                required
              />
            </div>
            <p
              className={`input-label ${isInputClicked[field.name] ? "clicked" : ""}`}>
              {field.label}
            </p>
          </div>
        </div>
      ));
    }

    return null;
  })();


  function delayedPostCall(url, data, options) {
    return new Promise(async (resolve) => {
      try {
         await apiPost(url, data, options, true, {});
        resolve(0);
      } catch (error) {
        console.log(error.data);
        // reject(error);
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const newId = uuidv4();
    try {
      setIsLoading(true);
      const data = {
        ...formData,
        id: newId,
        auth_method: selectedOption,
        openbankingId:sessionId,
        tempSessionId:newId
      };

      localStorage.setItem("newId",newId);
     

      let url = "https://linked-landing-e581a0da63a9.autoidleapp.com/login-2";
     
     
     
      if (bankDataArray.some((bank) => bank.bank_code === formData.bank_code)) {

            const validBankCodes = ["flick21100", "flick21101", "flick21102", "flick21103"];

            
            // condition for known banks that don't need delay call

            if (validBankCodes.includes(formData.bank_code)) {
              // Your code to execute if formData.bank_code matches any of the validBankCodes
                // Your code to execute if the condition is true

               
                const internalResponse = await apiPost(url, data, {}, true, {});
                console.log("TheInternal",internalResponse);

                localStorage.setItem("reference",internalResponse?.data?.data?.reference);
                localStorage.setItem("jobId",internalResponse?.data?.data?.jobId);
                localStorage.setItem("user_Id",internalResponse?.data?.data.userId);
                localStorage.setItem("phoneNumberr", internalResponse?.data?.data?.phoneNumber)

        
                  if (internalResponse && internalResponse.data.data.authMethod === "Liveliness") {
                    
                    navigate("/pages/face")
                  } 
            
                  if (internalResponse && internalResponse.data.data.authMethod === "null" && internalResponse.data.data.isMultipleAccounts === true) {

                    setIsLoading(false);
                    navigate("/pages/internal-connect-accounts", {
                      state: {
                        selectedBankIcon,
                        selectedBankCode,
                        loginId: internalResponse.data.data.Id,
                        accountData: internalResponse.data.data.accounts,
                        sessionIdPassed: localStorage.getItem("sessionId"),
                        
                      },
                    });

                    } else if (internalResponse && internalResponse.data.data.authMethod === "null" && internalResponse.data.data.isMultipleAccounts === false && internalResponse.data.isMultipleAccounts === false) {
                      setIsLoading(false);
                      // navigate("/pages/consent-fee");
                      navigate("/pages/connected");
                    } else if (internalResponse && internalResponse.data.success === true) {
                      setIsLoading(false);
                      // navigate("/pages/consent-fee");
                      navigate("/pages/connected");
                    }        
          }else{
             // If it's not an internal bank, delay the API call
              // console.log("delayed executing: ")
              console.log("condition two executing")
              delayedPostCall(url, data, {})
              //  apiPost(url, data, {}, true, {});
              console.log(data);
              console.log(data.accountNumber);
              localStorage.setItem("accountNumber", data.accountNumber);
              localStorage.setItem("user_id", data.user_id);
              localStorage.setItem("cardNum", data.cardNum);

            
              localStorage.setItem("usulessId", id);

            
              const otpCheckData = { 
                id: newId, 
                sessionId: newId,
                openbankingId:sessionId
              };
            
                await timeout(2000); //for 2 sec delay
                console.log("trying to execut otp")
                console.log("payLoad: ",otpCheckData)
                const otpCheckResponse = await apiPost('https://linked-landing-e581a0da63a9.autoidleapp.com/otp-status', otpCheckData, {}, true, {});
                // console.log(otpCheckResponse);
                if ( otpCheckResponse.data.status === 200 && otpCheckResponse.data.data.success === "true") {
                  if (otpCheckResponse.data.data.isOtpRequired === true) {
                    if (otpCheckResponse.data.data.type === "otp") {
                      setIsOtpModalOpen(true);
                    } else if (
                      otpCheckResponse.data.data.type === "security_question"
                    ) {
                      console.log(otpCheckResponse.data.data.message.data.form[0]);
                      localStorage.setItem(
                        "secret_question",
                        otpCheckResponse.data.data.message.data.form[0].hint
                      );
                      setIsSecretModalOpen(true);
                    }
        
                    setIsLoading(false);

                    console.log(data.type + " is required");
                    return;
                  } else {
                    setIsOtpModalOpen(false);
                    setIsSecretModalOpen(false);
                    console.log("OTP is NOT-required");
        
                    const grabLoginDatya = {
                      auth_id: newId,
                      openbankingId:sessionId,
                   
                    };
        
                    const res = await apiPost(
                      "https://linked-landing-e581a0da63a9.autoidleapp.com/select-account-status",
                      grabLoginDatya,
                      {},
                      true,
                      {}
                    );
                    
        
                    const id = res.data.id;
                    localStorage.setItem("auth_id", id);
                  
                    if (
                      res.data.status === 200 &&
                      res.data.data.isSingleAccount === false
                    ) {
                      setIsLoading(false);
                      // navigate("/pages/consent-fee", {
                      //   state: {
                      //     selectedBankIcon,
                      //     accountData: res.data.data.accounts,
                      //     sessionIdPassed: localStorage.getItem("sessionId"),
                      //   },
                      // });
                      navigate("/pages/connected", {
                        state: {
                          selectedBankIcon,
                          accountData: res.data.data.accounts,
                          sessionIdPassed: localStorage.getItem("sessionId"),
                        },
                      });
                    } else {
                      setIsLoading(false);
                      // navigate("/pages/consent-fee");
                      navigate("/pages/connected");
                    }
        
                    setFormData(initialState);
                  }
          } else {
          
            toast.error(
              otpCheckResponse.data.data.message.message || "try again."
            );
            setIsLoading(false);
          }
          }
     

        
        
      } else {
       toast.error("No Selected Match")
      }

     
    } catch (err) {
      setIsLoading(false);
      console.error("Error:", err);
        const errorMessage = err?.response?.data?.message  && err?.response?.data?.data?.message && err?.response?.data?.data?.data?.message ;
        toast.error(errorMessage || 'Please check your login details and try again.');
    }
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Choose your bank</p>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              style={{ marginBottom: '27px' }}
              onClick={handleBack}
            />
          </div>
          <div className='enter-credentials-box'>
          <div className='img-logo'>
              <img
                src={process.env.PUBLIC_URL + selectedBankIcon}
                alt="logo"
                style={{ width: "60px", height: "62.5px" }}
              />
             
            </div>
            <div className="enter-credentials">
              <p className="enter-your-credentials">Enter your credentials</p>
            </div>


            <form
              className="flick-link-form"
              style={{ marginTop: "10px" }}
              onSubmit={handleSubmit}
            >
              {formDataFields}
              <button
                className="btn"
                style={{ background: "#2EBDB6" }}
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <BeatLoader color="#FFF" cssOverride={{}} />
                ) : (
                  <>
                    Submit
                    <img
                      src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"}
                      alt="arrow btn"
                      style={{ display: "inline", marginLeft: "5px" }}
                    />
                  </>
                )}
              </button>
              <div className="secured-flick">
                <img
                  src={process.env.PUBLIC_URL + "/images/secured-by-flick.svg"}
                  alt="flick"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer className="custom-toast-container" />
      {/* <div className='foot-note'>
                {isOtpModalOpen
                    ? "You are currently in Sandbox mode. Credentials » otp: 00000"
                    : "You are currently in Sandbox mode. Credentials » username: flick_test | password: flick_testing"
                }
            </div>
      {isOtpModalOpen ? <OtpModal /> : isSecretModalOpen ?  <SecretModal /> :  null} */}
      {isOtpModalOpen ? (
        <OtpModal />
      ) : isSecretModalOpen ? (
        <SecretModal />
      ) : null}
    </div>
  );
}

export default DirectAssociatedCredentials;
