import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
function VerifyPage() {
    const [isProcessing, setIsProcessing] = useState(false);
    const [mandateData,setMandateData] = useState({})

    useEffect(()=>{
        validateUrl();      
        fetchBank();
    // eslint-disable-next-line
    },[])

    const fetchBank = async()=>{
        try{
            const url = "https://flick-app.com/merchant/get-connect-banks"
            const res =  await axios.get(url)
           
            const {business_banks,personal_banks} = res.data.data
            // console.log("business_banks",business_banks)
            // console.log("personal_banks",personal_banks)
            localStorage.setItem("personal",JSON.stringify(personal_banks));
            localStorage.setItem("business",JSON.stringify(business_banks));

        }catch(err){
            console.log(err)
        }
    }

    const navigate = useNavigate();
    const validateUrl = async () => {

        setIsProcessing(true)
          const params  = window.location.href;
          const urlId = params.split('/');
          const id = urlId[urlId.length-1]
          
  
        try {
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `https://flick-app.com/direct-debit/validation?hash=${id}`,
            // url: `https://flick-app.com/direct-debit/validation?hash=7aYmQA1nw3Dy1tXh4Nk9Me`,
            headers: {
            },
          };
          const response = await axios.request(config);
          // console.log("validation response", JSON.stringify(response.data));
          console.log("validation response",response.data);
          const result = response.data?.data;
          setIsProcessing(false)

          const dashBoardData = {
            is_required_address : result.is_required_address,
            is_required_bvn : result.is_required_bvn,
            is_required_email : result.is_required_email,
            is_required_phone : result.is_required_phone,
            CustomerCode : result.CustomerCode,
            transactionId : result.transactionId,
            publicKey : result.publicKey,
            
          } 

          const mandate = {
            validity: result.total_expected_deductions,
            start_date:result.start_date,
            end_date:result.end_date,
            frequency: result.frequency,
            first_debit: result.amount_per_charge,
            total_debit: result.total_charge_amount,
            reference:result.transactionId
          }


          setMandateData(mandate)

          localStorage.setItem("dashboard",JSON.stringify(dashBoardData));

          if(result.source === 'api'){
            localStorage.setItem('bvn',result.bvn)
          }
          
    
          localStorage.setItem("customerCode",response?.data?.data?.CustomerCode);
          localStorage.setItem("transactionId",response?.data?.data?.transactionId);
          localStorage.setItem('banksAllowed', JSON.stringify(response?.data?.data?.banks_allowed?.banksData));
          localStorage.setItem("firstDebit",response?.data?.data?.amount_per_charge);
          localStorage.setItem("totalDebit", response?.data?.data?.total_charge_amount)
          localStorage.setItem("publicKey", response?.data?.data?.publicKey)
          localStorage.setItem("livePublicKey", response?.data?.data?.publicKey)
          localStorage.setItem("frequency", response?.data?.data?.frequency)
          localStorage.setItem("startDate", response?.data?.data?.start_date)
          localStorage.setItem("endDate", response?.data?.data?.end_date)
          localStorage.setItem("validity", response?.data?.data?.total_expected_deductions)
          localStorage.setItem("charge_date", response?.data?.data?.charge_date)
          localStorage.setItem("is_onetime", response?.data?.data?.is_onetime)
          localStorage.setItem("source", response?.data?.data?.source)
          localStorage.setItem("sessionId",response?.data?.data?.transactionId)

          
  
  
          if (response?.status === 200) {
            setIsProcessing(false);
            navigate('/pages/confirm-mandate',{state:{mandate}});
          }


      
 
        } catch (err) {
          console.log(err);
          setIsProcessing(false)
          // let errorMessage = "Unable to fetch data";
        //   let errorMessage = err.response.data.message;
        //   if (err.response && err.response.data && err.response.data.message) {
        //     errorMessage = err.response.data.message;
        //   }
          // localStorage.setItem("errorMessage", errorMessage);
        //   setIsProcessing(false);
        //   navigate('/invalid', { state: { errorMessage: errorMessage } });
        }
        
      };
  return (
    <div className="connect-account-overlay" style={{position:"relative", display:"flex", alignItems:"center", justifyContent:"center", height:"100%"}}>
          <div className='loading_overlay' style={{background:"#fff"}}>
              <div className='loader_icon' >
                {
                    isProcessing &&
                    <FadeLoader
                    cssOverride={{marginRight:"auto",marginLeft:"auto",marginTop:"50vh"}}
                    color="#259792"
                    />
                }
              </div>
          </div>
    </div>
  )
}

export default VerifyPage