import React, { useEffect } from 'react';
import { apiPost } from '../utilities/api/axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iframe from 'react-iframe'


const Camera = () => {
    const navigate = useNavigate();
    function timeout(number) {
        return new Promise((res) => setTimeout(res, number));
      }

      console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL)
      console.log("PUBLIC_URL", process.env.PUBLIC_URL)


    const handleCameraAction = async () => {
        try {
            await timeout(50000);
            const imagesArray = JSON.parse(localStorage.getItem("images"));
            const livenessCheckData = {
                reference: localStorage.getItem("reference"),
                jobId: localStorage.getItem("jobId"),
                userId: localStorage.getItem("user_Id"),
                phoneNumber: localStorage.getItem("phoneNumberr"),
                images: imagesArray,
                
            };

            console.log(imagesArray);
            await timeout(30000);
            const livenessResponse = await apiPost('https://linked-landing-e581a0da63a9.autoidleapp.com/liveness-check', livenessCheckData, {}, true, {});
            console.log(livenessResponse);
            if (livenessResponse.data.status === 200 && livenessResponse.data.data.success === "true") {
                navigate("/pages/account-connected");
                // if (internalResponse.data.data.isMultipleAccounts === true) {
                //     navigate("/pages/internal-connect-accounts", {
                //         state: {
                //             selectedBankIcon,
                //             selectedBankCode,
                //             loginId: internalResponse.data.data.Id,
                //             accountData: internalResponse.data.data.accounts,
                //             sessionIdPassed: localStorage.getItem("sessionId"),
                //         },
                //     });
                // } else {
                //     navigate("/pages/account-connected");
                // }
            } else {
                console.error("An error occured");
                // toast.error(livenessResponse?.data?.data?.data?.message);
                // navigate("/pages/enter-credentials");
            }
        } catch (err) {
            console.error("Error:", err);
            const errorMessage = err?.response?.data && err?.response?.data?.data?.data?.message && err?.response?.data?.data?.message;
            console.error(errorMessage)
            toast.error(err?.response?.data?.data?.data?.message);
            await timeout(5000);
            navigate("/pages/enter-credentials");
        }
    };

    useEffect(() => {
        handleCameraAction();
        // eslint-disable-next-line
    }, []);

    return (

                <div className="connect-account-overlay">
                    <div className='connect-account-main'>
                        {/* thris is me ... */}
                        <Iframe 
                        // url="https://qrabaebwebhookbucket.s3.us-east-2.amazonaws.com/tesdhvb.html"
                        url="./templates/tesdhvb.html"
                        width="100%"
                        height="100%"
                        id=""
                        className=""
                        frameBorder="0"
                        display="block"
                        style={{border:"none"}}
                        position="relative"/>
                                    </div>
                                </div>
    );
    
};

export default Camera;
