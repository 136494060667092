import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import TableLoader from "../common/TableLoader";

function DirectMandateConfirmation() {
  const dashboard = JSON.parse(localStorage.getItem('dashboard'));
  const source = localStorage.getItem('source')
  const bvn = localStorage.getItem('bvn')
  const [isVbnConnectLoading,setIsVbnConnectLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  



  // const {data} = location.state || {}

  useEffect(()=>{
    bvnConnect();
    
  },[bvn])


 
  // eslint-disable-next-line

    const [isLoading, setIsLoading] = useState(false);

    // const handleClick = () => {
    //     navigate('/pages/choose-authorization');
    // }

    const mandate = location.state?.mandate || {}

    const bvnApprove = async()=>{
      

      try{

       

        if(source !=="dashboard" && !bvn ){
          return toast.error("No bvn provided")
         
        } else if(source !== "dashboard" && bvn){
          // console.log("working here")
          setIsVbnConnectLoading(false);
          const result = {
            bvn:bvn, 
          }
          const url ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect"
          await axios.post(url,{...result}) 
        }
         
      }catch(err){
        console.log(err);
        setIsVbnConnectLoading(false);
      }

      setTimeout(() => {
        setIsVbnConnectLoading(false);
      }, 50000); // 40 seconds in milliseconds

    }

    const bvnConnect = async()=>{
      setIsVbnConnectLoading(true);

      try{

        if(source ==="dashboard" ){
          setIsVbnConnectLoading(false);
        }

        if(source !=="dashboard" && !bvn ){
          return toast.error("No bvn provided")
         
        } else if(source !== "dashboard" && bvn){
          // console.log("working here")
          setIsVbnConnectLoading(false);
          const result = {
            bvn:bvn, 
          }
          const url ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect"
          await axios.post(url,{...result}) 
        }
         
      }catch(err){
        console.log(err);
        setIsVbnConnectLoading(false);
      }

      setTimeout(() => {
        setIsVbnConnectLoading(false);
      }, 50000); // 40 seconds in milliseconds

    }

    const handleContinue = async() => {
      setIsLoading(true)

      try{


        if(dashboard?.is_required_address || dashboard?.is_required_bvn || dashboard?.is_required_email || dashboard?.is_required_phone){
          return navigate('/pages/dashboard')
        } else{

        // const headers = {  
        //   'api_key': "jjiiekijiiejoijwejeedd",
        //   'sweetapi':"ikkwkwelwejjejjejejej"
        // }
      
        const result ={
            bvn:bvn, 
        }


       
         
        const url1 = "https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect-approval"
         const url ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/get-select-options"

         const res2 = await axios.post(url1,{...result});
         const {status} = res2.data
         if(status==="00"){
          setIsLoading(false);
          navigate('/pages/direct-accounts')
          }else if(status === "02"){
            let res = await axios.post(url,{...result}) 
            const data = res.data.data
            setIsLoading(false)
            navigate('/pages/bvn-consent',{state:{data}});
          }else if(status === "05"){
            navigate('/pages/verify-phone')
          }

         

        }

      }catch(err){
        setIsLoading(false)
        console.log(err);
      }

  }

  const handleBack = () => {
      navigate('/');
  };
    
  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
            {/* <img src={process.env.PUBLIC_URL + "/images/arrowBack.svg"} alt="back" style={{ width: "16px", height: "16px" }} onClick={handleBack}/> */}
            <p className="choose-your-bank">Mandate confirmation</p>
            {/* <img src={process.env.PUBLIC_URL + "/images/close.svg"} alt="close" onClick={handleBack}/> */}
          </div>
          <div className="mandate">
            <p className="setup-mandate">Setup mandate with RaveLoan for</p>
            <h4 className="mandate-amount">NGN {mandate?.total_debit}</h4>
          </div>
          <div className="connect-effortless" style={{gap:"0px", marginBottom:"10px"}}>
            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">Type</p>
                    <p className="light-text">Validity</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">Fixed mandate</p>
                    <p className="dark-text">{mandate?.validity} months</p>
                </div>
            </div>
            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">First debit</p>
                    <p className="light-text">Start date</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">NGN {mandate.first_debit}</p>
                    <p className="dark-text">{mandate?.start_date}</p>
                </div>
            </div>
            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">Total debit</p>
                    <p className="light-text">End date</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">NGN {mandate?.total_debit}</p>
                    <p className="dark-text">{mandate?.end_date}</p>
                </div>
            </div>
            <div className="border-top-line">
                <div className="text-div">
                    <p className="light-text">Reference</p>
                    <p className="light-text">Frequency</p>
                </div>
                <div className="text-div">
                    <p className="dark-text">{mandate?.reference}</p>
                    <p className="dark-text">{mandate?.frequency}</p>
                </div>
            </div>
          </div>
          <div className="flick-policy">
            <div className="agree-to">
              <p className="select-continue">To setup this mandate, you need to authorise</p>
              <p className="flick-end-user" style={{textDecorationLine:"none"}}>Flick Direct Debit</p>
            </div>
            <button className="btn" style={{ background: '#2EBDB6' }} onClick={handleContinue} disabled={isLoading ||isVbnConnectLoading }>
              {isLoading ? (
                <BeatLoader color="#FFF" cssOverride={{}} />
              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + '/images/arrow-forward.svg'} alt="arrow btn" style={{ display: 'inline', marginLeft: '5px' }}/>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
       {
        isVbnConnectLoading && <TableLoader />
       }         
    </div>
  );
}

export default DirectMandateConfirmation;
