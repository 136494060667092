import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

 function ConsentReceived() {
  const navigate = useNavigate();


  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      window.location.replace('/');
    });

    return () => {
      window.removeEventListener('popstate', function(event) {
        window.location.replace('/');
      });
    };
  }, []);



  const handleContinue = async () => {
    navigate("/pages/rave-loan")
  }

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className='success-flick' style={{marginTop:"37px"}}>
            <img src={process.env.PUBLIC_URL + "/images/success-icon.svg"} alt="logo"/>
            <img src={process.env.PUBLIC_URL + "/images/flick-white-border.svg"} alt="logo" style={{position:"absolute", bottom:"0", right:"100px"}}/>
          </div>

          <div className='consent'>
            <p className='account-connected'>Consent Received </p>
            <p className='your-account' style={{width:"90%"}}>Consent was successful; you can now proceed to link your bank accounts</p>
          </div>
          <div className='consent-btn'>
              <button className="btn" onClick={handleContinue}>
                    Continue
              </button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ConsentReceived;

