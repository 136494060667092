import React, { useState } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


function DirectLinkAccount() {

  const navigate = useNavigate();
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');

  const initialState = {
    accountNumber: '',
  };
// eslint-disable-next-line
  const [formData, setFormData] = useState(initialState);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [isInputClicked, setIsInputClicked] = useState({
    accountNumber: false,
  });
  const handleBack = () => {
    navigate('/pages/direct-choose-account');
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate('/pages/debit-mandate-confirmation');
  }

  return (
    <div className="connect-account-overlay">
       <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Link account</p>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              style={{ marginBottom: '27px' }}
              onClick={handleBack}
            />
          </div>
          <div className='enter-credentials-box'>
            <div className='img-logo'>
              <img
                src={process.env.PUBLIC_URL + selectedBankIcon}
                alt="logo"
                style={{width: "47px", height: "48.958px"}}
              />
            </div>
            <form className="flick-link-form" style={{ marginTop: '10px' }} onSubmit={handleSubmit}>

              <div className="input_container" onBlur={() => handleInputBlur('accountNumber')}>
                <div className={`input-group ${isInputClicked.accountNumber ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('accountNumber')}>
                  <input type="text" name="accountNumber" placeholder="Account number" className={`input-name`} onChange={handleInputChange} required />
                </div>
                <p className={`input-label ${isInputClicked.accountNumber ? 'clicked' : ''}`}>Account number </p>
              </div>
              <div className='img-account-name'>
                <img src={process.env.PUBLIC_URL + "/images/check-circle-broken.svg"} alt="logo"/>
                <p className='account-name-img'>AJIBADE AYOMIDE</p>
              </div>
              <button className="btn" style={{ background: '#2EBDB6' }} type="submit" onClick={handleSubmit} disabled={isLoading} >
                {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Link Account
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer className="custom-toast-container" />
    </div>
  );
}

export default DirectLinkAccount;
