import React, { useState, useRef } from "react";
import { apiPost } from '../../utilities/api/axios';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import ConnectAccountModal from "../ConnectAccountModal";
import { toast } from "react-toastify";

function timeout(number) {
    return new Promise( res => setTimeout(res, number) );
}



function SecretModal() {
    console.log("i popped up modal yaaay!!!")
  const [secret, setSecret] = useState("");
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSingleAccount] = useState(true);
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');
  const user_id = localStorage.getItem('user_id');
  const sessionId = localStorage.getItem('sessionId');
  const newId = localStorage.getItem('newId');
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSecret(value);
    console.log("na me enter this value ooooo " + value);
  };
  const handleSecretQuestionSubmit = async () => {
        console.log("i just submitted my secret question")
        setIsLoading(true);
    try {

      const secretQuestionValue = secret;
      const secretQuestionData = {
        security_answer: secretQuestionValue,
        security_question:localStorage.getItem('secret_question'),
        user_id : user_id,
        bank_code: localStorage.getItem('selectedBankCode'),
        sessionId: newId,
        openbankingId:sessionId
      };
      console.log("this is the secretQuestionData sent");
      console.log(secretQuestionData);

        const secretQuestionInitiateResponse = await apiPost(
            'https://linked-landing-e581a0da63a9.autoidleapp.com/security-question',
            secretQuestionData,
            {},
            true,
            {}
        );

      //checkSingleAccount22
      console.log("this is the secretQuestionInitiateResponse");
      console.log(secretQuestionInitiateResponse);
      if (secretQuestionInitiateResponse.status === 200 ) {

        const grabLoginDatya = {
            auth_id:newId,
            openbankingId:sessionId
        };
        
    // check if this response carries success or carries multiple accounts //
        await timeout(2000); //for 1 sec delay
        // const securityQuestionStatus = await apiPost('https://linked-landing-e581a0da63a9.autoidleapp.com/security-question-status', grabLoginDatya, {}, true, {});
        // console.log("securityQuestionStatus response ");
        // console.log(securityQuestionStatus)
        
          const res = await apiPost('https://linked-landing-e581a0da63a9.autoidleapp.com/select-account-status', grabLoginDatya, {}, true, {});
          console.log("select-account-status response ");
          console.log(res)
          console.log("stepping into the response", res.data)
          
          const id = res.data.id;
          localStorage.setItem('auth_id', id);
        //   localStorage.setItem('sessionId', id);

          
          // check if this response carries success or carries multiple accounts //
          // if(res && res.data && Array.isArray(res.data.data)) {    
           if(res.data.status === 200 && res.data.data.isSingleAccount === false) {    
              setIsLoading(false);
              navigate('/pages/consent-fee', { state: { selectedBankIcon, accountData: res.data.data.accounts, sessionIdPassed:localStorage.getItem('sessionId')} });
            } else {
              setIsLoading(false);
              navigate('/pages/consent-fee');
            }

      } else {
        console.error('Failed');
        toast.error('Failed');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error Secret Question Submission:', error);
      const errorMessage = 'Failed';
      console.log('Error response:', error);
      toast.error(errorMessage);
    }
  };

  return (
    <div className="enter-otp-modal">
      <div>
        <div className="modal-wrapper">
          <p className="enter-otp">{localStorage.getItem('secret_question')}</p>
          <div className="input-otp">
            <div className="input-otp-field">
              <input
                type="text"
                name="secret"
                className="otp"
                value={secret}
                onChange={handleInputChange}
                ref={inputRef}
              />
            </div>
          </div>
          <button className="otp-btn" onClick={handleSecretQuestionSubmit}>
            {isLoading ? (
              <BeatLoader color="#FFF" cssOverride={{}} />
            ) : (
              <>Submit</>
            )}
          </button>
        </div>
      </div>
      {!isSingleAccount && <ConnectAccountModal />}
    </div>
  );
}

export default SecretModal;
