import React, { useState, useRef } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';


const InnerContainer = styled.div`
width:100%;
border-radius: var(--radius-xl, 12px);
border: 1px solid var(--Card-stroke, #E7EAEE);
background: #FFF;
display: flex;
align-items:center;
justify-content:center;
margin-top:33px;
.wrapper{

  .consent{
    width:100%;
    border-radius: 8px;
    background: #F4FBFB;
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-left:5px;
    padding-right:5px;
    
    .consent_text{
      color: #121212;
      text-align: center;
      font-family: "Circular Std";
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px; /* 175% */
    }
  }
  .divider{
    width:100%;
    border: 1px dotted #D5D5D5;
  }
  .nuban{
    width:100%;
    margin-top:24px;
    display:flex;
    flex-direction:column;
    gap:20px;
    margin-bottom:24px;
    .nuban_row{
      display:flex;
      justify-content:space-between;
      align-items:center;

      .copy{
        display: flex;
        align-items:center;
        gap:8px;
      }

      .nuban_row_desc{
        color: #707070;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
      }

      .nuban_row_value{
        color: #121212;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 128.571% */
      }
    }
  }
  width:90%
}
`



function InputOtp() {

  const navigate = useNavigate();
  const selectedBank = localStorage.getItem('selectedBank');
  const bankName = localStorage.getItem('bankName');


  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState([""]);
  const inputRef = useRef(null);

  const handleBack = () => {
    navigate('/pages/direct-enter-credentials');
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/) && value.length <= 5) {
      setOtp(value);
    }
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);
  
  setIsLoading(false);
  navigate('/pages/connected');
    //     setOtp("");
   

// const hardcodedOtp = "00000";

// if (otp === hardcodedOtp) {
//     navigate('/pages/connect-successful');
// } else {
//   setIsLoading(false);
//     toast.error("Input the correct OTP");
//     setOtp("");
// }
};


  return (
    <div className="connect-account-overlay">
      {console.log("bankName",bankName)}
       <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBackward.svg'}
              alt="back"
              // style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
           
            {/* <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              style={{ marginBottom: '17px' }}
              onClick={handleBack}
            /> */}
          </div>
          <div className='enter-credentials-box'>
          {/* <div className='img-logo'>
              {selectedBank === 'gtb' && (
                <img
                  src={process.env.PUBLIC_URL + '/images/gtbank.svg'}
                  alt="GTBank"
                  style={{ width: '60px', height: '62.5px' }}
                />
              )}
              {selectedBank === 'zenith' && (
                <img
                  src={process.env.PUBLIC_URL + '/images/zenith-bank.svg'}
                  alt="Zenith Bank"
                  style={{ width: '60px', height: '62.5px' }}
                />
              )}
              {selectedBank === 'kuda' && (
                <img
                  src={process.env.PUBLIC_URL + 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/kuda-bank-icon.png'}
                  alt="Kuda Bank"
                  style={{ width: '60px', height: '62.5px' }}
                />
              )}
          </div> */}

          <p style={{marginTop:"16px"}} className="choose-your-bank">Authorise Direct Debit</p>

          <InnerContainer>
            <div className='wrapper'>
              <div className='consent'>
                <p className='consent_text'>For Direct Debit consent, pay <span>N50</span>  from your <span style={{fontSize:"14px",fontWeight:"500"}}>{bankName}</span> Bank account (3089389252)</p>
              </div>

              <div className='divider'></div>

              <div className='nuban'>
                  <div className='nuban_row'>
                    <p className='nuban_row_desc'>Account Name</p>
                    <p className='nuban_row_value'>PAYSTACK - TITAN</p>
                  </div>
                  <div className='nuban_row'>
                    <p className='nuban_row_desc'>Account Number</p>
                    <div className='copy'>
                      <p className='nuban_row_value'>9880218357</p>
                      <img src='/images/copyicon.svg' alt=''/>
                    </div>
                  </div>
                  <div className='nuban_row'>
                    <p className='nuban_row_desc'>Account Type</p>
                    <p className='nuban_row_value' style={{textAlign:"right",fontSize:"12px"}}>NIGERIA INTERBANK SETTLEMENT SYSTEM PLC</p>
                  </div>
              </div>
            </div>

          </InnerContainer>


            <button className="btn" onClick={handleSubmit} disabled={isLoading} style={{marginTop:"46px"}} >
                {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    I’ve made payment
                  </>
                )}
              </button>
          </div>
        </div>
      </div>
      <ToastContainer className="custom-toast-container" />
    </div>
  );
}

export default InputOtp;
