import React, { useState, useRef, useEffect } from 'react';
import './connectAccount.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';


function TransactionOtp() {

  const navigate = useNavigate();
  const location = useLocation();

  const bvn = localStorage.getItem('bvn')

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const [otp, setOtp] = useState([""]);
  const inputRef = useRef(null);
  const [isChecked, setChecked] = useState(false);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);


  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleCheckboxChange = () => {
    setChecked(!isChecked); // Toggle the checkbox state
  };
  // const con =  location?.state || {}


  const handleBack = () => {
    navigate('/pages/bvn-consent');
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/) && value.length <= 6) {
      setOtp(value);
    }
  };


 const handleResendOTP = async ()=>{
    if (canResend) {
      setTimer(60);  // Reset timer to 60 seconds
      setCanResend(false); // Disable resend button

      const result = {
        "bvn":bvn,
       "otp": "resend otp"
      };
      console.log("resend otp result: ", result)
      const url ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect-otp"
      const res = await axios.post(url,{...result})  
      console.log('Resend OTP',res.data);
    }
 }


  const handleSubmit = async (e) => {
    e.preventDefault();
    if ( !otp.match(/^[0-9]{1,6}$/)) return toast.error("Input the correct OTP");
    if(!isChecked) return "you must agree to flick user privacy policy"
    setIsLoading(true);
      
    try{

        const result = {
          "bvn":bvn,
          "otp":otp
        }

        const result2 = {
          bvn:bvn
        }
      

        console.log("otp data: ",result)
        const url ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect-otp"
        let res = await axios.post(url,{...result}) 

        const url2 ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/bvn-connect-approval"
        const res2 = await axios.post(url2,{...result2})
        const {status} = res2.data
        if(status==="00"){
          setIsLoading(false);
          navigate('/pages/direct-accounts')
      }else if(status === "02"){
          const url3  ="https://948qrw65dh.execute-api.us-east-2.amazonaws.com/production/bvn/get-select-options"
          let res3 = await axios.post(url3, { ...result2 });
          const data = res3.data.data;
          navigate('/pages/bvn-consent', { state: { data } });
      }else if(status === "05"){
          navigate('/pages/verify-phone')
      }
        setIsLoading(false)
        navigate('/pages/consent-received')

    }catch(err){
      setIsLoading(false)
      console.log(err)
    }

};


  return (
    <div className="connect-account-overlay">
       <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              style={{ marginBottom: '17px' }}
              onClick={handleBack}
            />
          </div>
          <div className='enter-credentials-box'>
            <div className='img-logo'>
              <img
                src={process.env.PUBLIC_URL + '/images/round-flick-logo.svg'}
                alt="logo"
                style={{width: "47px", height: "48.958px"}}
              />
            </div>

           <div className='enter-transaction'>
            <p className='enter-otp'>Enter Transaction OTP</p>
            <p className='five-digit'>Enter the 5-digit code sent to your phone</p>
           </div>

           <div className="input-verify-otp">
            <div className="input-verify-field">
            <input
                type="password"
                name="otp"
                maxLength="6"
                className="-otp"
                value={otp}
                onChange={handleInputChange}
                ref={inputRef}
              />
            </div>
            <p className="code">
            Didn't get a code? <span className="click" onClick={handleResendOTP} style={{ color: canResend ? '#2EBDB6' : 'grey', cursor: canResend ? 'pointer' : 'default' }}>
                  {canResend ? 'Click to resend.' : `Resend in ${timer}s`}
                </span>
            </p>
          </div>

          <div className='check-policy'>
            <input 
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                className="custom-checkbox"
                style={{padding:"5px", width:"15px", height:"15px"}}
            />
           <p className='proceeding'>By “proceeding” you agree to the <span><a href="https://getflick.app/privacy-policy" className='end-user'>Flick End User Privacy Policy</a></span></p>
          </div>

            <button className="btn" onClick={handleSubmit} disabled={isLoading} >
                {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
                ) : (
                  <>
                    Confirm
                  </>
                )}
              </button>
          </div>
        </div>
      </div>
      <ToastContainer className="custom-toast-container" />
    </div>
  );
}

export default TransactionOtp;
