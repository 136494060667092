import React, { useState, useRef, useEffect } from "react";
import { apiPost } from '../../utilities/api/axios';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from "react-spinners";
import ConnectAccountModal from "../ConnectAccountModal";
import { toast } from "react-toastify";

function timeout(number) {
    return new Promise( res => setTimeout(res, number) );
}

function OtpModal() {
    console.log("i popped up modal yaaay!!!")
  const [otp, setOtp] = useState([""]);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
//   const [isSingleAccount, setIsSingleAccount] = useState(true);
  const [isSingleAccount] = useState(true);
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');
//   const selectedBankCode = localStorage.getItem('selectedBankCode');
//   const accountNumber = localStorage.getItem('accountNumber');
  const user_id = localStorage.getItem('user_id');
  const newId = localStorage.getItem('newId');
  
//   const cardNum = localStorage.getItem('cardNum');
    const sessionId = localStorage.getItem('sessionId');


  useEffect(() => {
    // const checkSingleAccount = async () => {
    //   try {
    //     const accountNumber = localStorage.getItem('accountNumber');
    //     const user_id = localStorage.getItem('user_id');
    //     const cardNum = localStorage.getItem('cardNum');
    //     const isSingleAccountCheckData = {
    //       id: user_id || cardNum || accountNumber,
    //     };
    //     const isSingleAccountCheckResponse = await apiPost(
    //       'https://linked-landing-e581a0da63a9.autoidleapp.com/get-index',
    //       isSingleAccountCheckData,
    //       {},
    //       true,
    //       {}
    //     );
    //     const isSingleAccount = isSingleAccountCheckResponse.data.status === 200;
    //     setIsSingleAccount(isSingleAccount);
    //     console.log(isSingleAccountCheckResponse.data);
    //   } catch (error) {
    //     console.error('Error checking if it is a single account:', error);
    //   }
    // };

    //checkSingleAccount();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/) && value.length <= 6) {
      setOtp(value);
    }
    console.log("na me enter this value ooooo " + value);
  };
  const handleOtpSubmit = async () => {
      console.log("i just sumitted otp")
    setIsLoading(true);
    try {

      const otpValue = otp;
      const otpVerificationData = {
        otp: otpValue,
        id: user_id,
        sessionId: newId,
        openbankingId:sessionId
      };
      console.log("this is the otpVerificationData sent");
      console.log(otpVerificationData);

        const otpInitiateResponse = await apiPost(
            'https://linked-landing-e581a0da63a9.autoidleapp.com/otp',
            otpVerificationData,
            {},
            true,
            {}
        );

      //checkSingleAccount22
      console.log("this is the otpInitiateResponse");
      console.log(otpInitiateResponse);
      if (otpInitiateResponse.status === 200 ) {

    // check if this response carries success or carries multiple accounts //
        const grabLoginDatya = {
            auth_id: newId,
            openbankingId:sessionId,
            
        };

        // const grabLoginDatyaOItp = {
        //     sessionId: localStorage.getItem('sessionId')
        // };
        
        await timeout(2000); //for 1 sec delay
        // const otpStatus = await apiPost('https://linked-landing-e581a0da63a9.autoidleapp.com/otp-status', grabLoginDatyaOItp, {}, true, {});
        // console.log("otp-status response ");
        // console.log(otpStatus)

        // if(otpStatus.status === 200){
            
        // }else{

        // }
          const res = await apiPost('https://linked-landing-e581a0da63a9.autoidleapp.com/select-account-status', grabLoginDatya, {}, true, {});
          console.log("select-account-status response ");
          console.log(res)
          console.log("stepping into the response", res.data)
          
          const id = res.data.id;
          localStorage.setItem('auth_id', id);
        //   localStorage.setItem('sessionId', id);

          
          // check if this response carries success or carries multiple accounts //
          // if(res && res.data && Array.isArray(res.data.data)) {    
           if(res.data.status === 200 && res.data.data.isSingleAccount === false) {    
              setIsLoading(false);
              // navigate('/pages/consent-fee', { state: { selectedBankIcon, accountData: res.data.data.accounts, sessionIdPassed:localStorage.getItem('sessionId')} });
              navigate('/pages/connected', { state: { selectedBankIcon, accountData: res.data.data.accounts, sessionIdPassed:localStorage.getItem('sessionId')} });
            } else {
              setIsLoading(false);
              // navigate('/pages/consent-fee');
              navigate('/pages/connected');
            }
    
        //   setFormData(initialState);  

        // // verify otp status // 
        // const otpVerifyResponse = await apiPost(
        //     'https://linked-landing-e581a0da63a9.autoidleapp.com/verify-otp',
        //     otpVerificationData,
        //     {},
        //     true,
        //     {}
        //   );
        //   console.log("this is the otpVerifyResponse !!!", otpVerifyResponse);
        //   const verifybabami = otpVerifyResponse.data.status === "00" ? true : otpVerifyResponse.data.status === "01" ?  false : false;
          
        //   if(verifybabami === true){
        //         const checkmeOtp = await checkSingleAccount22();
        //         // if (otpVerificationResponse.data.msg === "otp sent successfully") {
        //             setIsLoading(false);
        //         // if (isSingleAccount) {
        //         if (checkmeOtp === true) {
        //                 navigate('/account-connected');
        //         } else {
        //         setIsLoading(false);
        //         }      
        //   }else{

        //     setIsLoading(false);
        //   }


      } else {
        console.error('OTP verification failed');
        toast.error('OTP verification failed');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error during OTP verification:', error);
      const errorMessage = 'OTP verification failed';
      console.log('Error response:', error.response);
      toast.error(errorMessage);
    }
  };

  return (
    <div className="enter-otp-modal">
      <div>
        <div className="modal-wrapper">
          <p className="enter-otp">Enter OTP</p>
          <div className="input-otp">
            <div className="input-otp-field">
              <input
                type="text"
                name="otp"
                maxLength="6"
                className="otp"
                value={otp}
                onChange={handleInputChange}
                ref={inputRef}
              />
            </div>
          </div>
          <button className="otp-btn" onClick={handleOtpSubmit}>
            {isLoading ? (
              <BeatLoader color="#FFF" cssOverride={{}} />
            ) : (
              <>Submit</>
            )}
          </button>
        </div>
      </div>
      {!isSingleAccount && <ConnectAccountModal />}
    </div>
  );
}

export default OtpModal;
