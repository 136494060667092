import React, { useState } from 'react';
import './connectAccount.scss';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';

function DirectChooseYourAccount() {

  const navigate = useNavigate();
  const selectedBankIcon = localStorage.getItem('selectedBankIcon');
  const selectedBankCode = localStorage.getItem('selectedBankCode');
  const [selectedOption, setSelectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleClick = () => {
    if (!selectedOption) {
      toast.error('Please select an account type');
      return;
    }

    // if (!connectionMethod(selectedBankCode, selectedOption)) {
    //   toast.error(
    //     `Connection method not avaiable`
    //   );
    //   return;
    // }

    setIsLoading(true);
    localStorage.setItem('selectedOption', selectedOption);
    setIsLoading(false);
      navigate('/pages/direct-link-account', {
        state: {
          selectedBankIcon: selectedBankIcon,
          selectedBankCode: selectedBankCode,
        },
      });
    // if (selectedBankCode) {
    //   setIsLoading(false);
    //   navigate('/pages/link-account', {
    //     state: {
    //       selectedBankIcon: selectedBankIcon,
    //       selectedBankCode: selectedBankCode,
    //     },
    //   });
    // } else {
    //   setIsLoading(false);
    //   console.error('Error:', 'Bank code cannot be empty');
    // }
  };

  const handleBack = () => {
    navigate('/pages/select-bank');
  };

  return (
    <div className="connect-account-overlay">
      <div className="connect-account-main">
        <div className="connect-account-box">
          <div className="choose-bank">
            <img
              src={process.env.PUBLIC_URL + '/images/arrowBack.svg'}
              alt="back"
              style={{ width: '16px', height: '16px' }}
              onClick={handleBack}
            />
            <p className="choose-your-bank">Choose your account type</p>
            <img
              src={process.env.PUBLIC_URL + '/images/close.svg'}
              alt="close"
              onClick={handleBack}
            />
          </div>
          <div className="flick-logo" style={{ marginTop: '20px'}}>
            <img
              src={process.env.PUBLIC_URL + selectedBankIcon}
              alt="bank logo"
              style={{width: "47px", height: "48.958px"}}
            />
          </div>
          <div className="connect-effortless">
              <div className='border-line'>
                  <div className='connect-title' onClick={() => setSelectedOption('personal_account')}>
                      <img src={process.env.PUBLIC_URL + "/images/personal-account.svg"} alt="logo"/>
                      <div className='connect-link'>
                          <p className='connect-your-account'>Personal account</p>
                          <p className='securely-connect' style={{marginBottom:"18px"}}>Setup this direct debit mandate with a personal account</p>
                      </div>
                      <input
                        type="radio"
                        name="options"
                        value="personal_account"
                        checked={selectedOption === 'personal_account'}
                        onChange={handleOptionChange}
                        className="custom-radio"
                      />
                  </div>
              </div>
              <div className='border-line'>
                  <div className='connect-title' onClick={() => setSelectedOption('business_account')}>
                      <img src={process.env.PUBLIC_URL + "/images/business-account.svg"} alt="logo"/>
                      <div className='connect-link'>
                          <p className='connect-your-account'>Business account</p>
                          <p className='securely-connect' style={{marginBottom:"18px"}}>Setup this direct debit mandate with a business account</p>
                      </div>
                      <input
                        type="radio"
                        name="options"
                        value="business_account"
                        checked={selectedOption === 'business_account'}
                        onChange={handleOptionChange}
                        className="custom-radio"
                      />
                  </div>
              </div>
              <div className='connect-title' onClick={() => setSelectedOption('joint_account')}>
                  <img src={process.env.PUBLIC_URL + "/images/joint-account.svg"} alt="logo"/>
                  <div className='connect-link'>
                      <p className='connect-your-account'>Joint account</p>
                      <p className='securely-connect'>Setup this direct debit mandate with a joint account</p>
                  </div>
                  <input
                    type="radio"
                    name="options"
                    value="joint_account"
                    checked={selectedOption === 'joint_account'}
                    onChange={handleOptionChange}
                    className="custom-radio"
                  />
              </div>
            <button
              className="btn"
              style={{ background: '#2EBDB6' }}
              onClick={handleClick}
              disabled={isLoading}
            >
              {isLoading ? (
                <BeatLoader color="#FFF" cssOverride={{}} />
              ) : (
                <>
                  Continue
                  <img src={process.env.PUBLIC_URL + '/images/arrow-forward.svg'} alt="arrow btn" style={{ display: 'inline', marginLeft: '5px' }}/>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default DirectChooseYourAccount;
