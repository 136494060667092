import React, { useEffect } from 'react'
import '../../components/checkout/layout/layout.scss'
import { useNavigate} from 'react-router-dom'

// grab from local storage or props //


function Success() {
  useEffect(()=>{
      const result = JSON.parse(localStorage.getItem('auth'))  
      if(result?.source === "api"){
        localStorage.removeItem("auth")
      }
  // eslint-disable-next-line
  },[])
  
  
    const navigate = useNavigate();
    const handleHome = ()=>{
        navigate('/')
    }

    const handleBack = ()=>{
        navigate('/')
    }

  return (
    <div className='layout_success'>
      <div className='header'>
        <img onClick={handleHome} src="/images/Flick.svg" alt='logo'/>
      </div>
      <div className='main_success'>
        <div className=' box_center'>
            <img className='success_img' src='/images/success.svg' alt='success'/>
            <h1 className='success_title'>Payment Successful </h1>
            <p className='success_desc'>You made a payment of NGN10000 to Flick Checkout</p>
            <button onClick={handleBack} className='btn'>Go Home</button>
        </div>
        <div className='securedBtn'>
            <img src='/images/lockUnlocked.svg' alt='lock' />
            <div className='secured_content'>
                <p className='secured_p'>secured by</p>
                <img src='/images/flickLogo.svg' alt='logo'/>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Success
